<template>
  <div class="statistics-subscriptions">
    <div class="header">
      <div class="title" bold>{{ $locale.words["subscriptions"] }}</div>
      <div class="filter pointer dactive" @click="openFilter">
        <div class="filter-title">
          <span v-if="type === 'new'">{{ $locale.words["newa"] + " " + lowercase($locale.words["subscriptions"]) }}</span>
          <span v-else-if="type === 'expired'">{{ $locale.words["subscriptions"] + " " + lowercase(lang["expired"]) }}</span>
          <span v-else-if="type === 'cancelled'">{{ $locale.words["subscriptions"] + " " + lowercase(lang["cancelled"]) }}</span>
        </div>
        <a class="filter-button"><iconic name="mf_filter" /></a>
      </div>
    </div>
    <div class="period">
      <div class="period-wrap">
        <div class="period-content">
          <a :class="`period-button ${period === 'last-month' && 'selected'}`" @click="setPeriod('last-month')">
            <span>{{ $locale.words["last"] + " " + $locale.words["month"] }}</span>
          </a>
          <a :class="`period-button ${period === 'last-3-months' && 'selected'}`" @click="setPeriod('last-3-months')">
            <span>{{ $locale.words["last"] + "s 3 " + $locale.words["months"] }}</span>
          </a>
          <a :class="`period-button ${period === 'last-6-months' && 'selected'}`" @click="setPeriod('last-6-months')">
            <span>{{ $locale.words["last"] + "s 6 " + $locale.words["months"] }}</span>
          </a>
        </div>
      </div>
    </div>
    <div :class="`chart ${loading && 'loading'}`" relative>
      <area-chart :data="statistics.months" ref="chartkick" v-bind="chartProps"></area-chart>
    </div>
    <StatisticsSubscriptionsFilter ref="context" @type="setType" />
  </div>
</template>

<script>
export default {
  components: {
    StatisticsSubscriptionsFilter: () => import("./StatisticsSubscriptionsFilter.vue"),
  },
  data: function() {
    return {
      loading: true,
      period: "last-6-months",
      type: "new",
      statistics: {},
      chartProps: {
        colors: ["#bd0909"],
        library: {
          discrete: true,
        },
      },
    };
  },
  methods: {
    openFilter: function() {
      this.$refs.context.open();
    },
    setPeriod: function(period) {
      this.period = period;
      this.getStatistics();
    },
    setType: function(type) {
      this.type = type;
      this.getStatistics();
    },
    getStatistics: async function() {
      this.isLoading(true);
      try {
        const query = `?period=${this.period}&type=${this.type}`;
        const response = await this.$api.get(`${process.env.VUE_APP_API_HOST}statistics/subscriptions${query}`);
        this.statistics = response.data?.statistics;
      } catch (error) {
        this.ialert.go({ title: "Error", message: error });
      }
      this.isLoading(false);
    },
  },
  mounted: function() {
    setTimeout(() => {
      this.chartProps.label = this.lang.subscriptions;
      this.chartProps.ytitle = this.lang.subscriptions;
      this.chartProps.prefix = "$";
      this.getStatistics();
    }, 500);
  },
  computed: {
    lang: function() {
      return this.$locale.statistics;
    },
  },
};
</script>

<style lang="scss">
.statistics-subscriptions {
  .header {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px $alto;
    .title {
      padding: $mpadding;
      display: flex;
      align-items: center;
    }
  }
  .filter {
    padding: $mpadding;
    display: flex;
    align-items: center;
    &-button {
      margin-left: $mpadding/2;
      font-size: 130%;
      color: $primary_color;
    }
  }
  .period {
    padding: $mpadding;
    &-wrap {
      overflow-x: auto;
    }
    &-content {
      display: flex;
      justify-content: space-evenly;
      gap: $mpadding/2;
    }
    &-button {
      @include Flex(inherit, center, center);
      background-color: $gray2;
      font-size: 80%;
      cursor: pointer;
      flex: 1;
      text-align: center;
      padding: $mpadding/1.7;
      border-radius: 2pc;
      &.selected {
        background-color: $primary_color_saturate;
      }
    }
  }
  .chart {
    svg {
      max-width: 100%;
      height: 100%;
      > g > rect {
        border-left: solid 1px $alto;
      }
    }
  }
  @media (min-width: $tablet_width) {
    .period {
      &-content {
        gap: $mpadding;
      }
      &-button {
        font-size: 100%;
      }
    }
  }
}
</style>
